export enum PasswordStrength {
	Weak,
	Medium,
	Strong,
}

export const passwordStrengthRequirement: string[] = [];
passwordStrengthRequirement[PasswordStrength.Weak] = 'Password must be 6-20 characters long';
passwordStrengthRequirement[PasswordStrength.Medium] = 'Password must be 6-20 characters long, contain letters and numbers';
passwordStrengthRequirement[PasswordStrength.Strong] = 'Password must be 6-20 characters long, contain at least 1 lowercase letter, uppercase letter, numbers and special characters';

export const passwordStrengthRegex: RegExp[] = [];
passwordStrengthRegex[PasswordStrength.Strong] = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/;
passwordStrengthRegex[PasswordStrength.Medium] = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,20})/;
passwordStrengthRegex[PasswordStrength.Weak] = /^(?=.{6,20})/;
