

















import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseInput from '@/components/inputs/BaseInput.vue';


@Component
export default class EmailInput extends BaseInput {

	@Prop({default: true}) public readonly showInvalid!: boolean;
	@Prop({default: 'Enter your email'}) public readonly placeholder!: string;

	public input: string = '';


	get invalidError() {
		return `Invalid Email.`;
	}

	public validate(value: string) {
		if (value === null) { return false; }
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
	}
}
