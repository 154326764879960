















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import { OAuthSigninMethod, User } from '@/store/firestore';
import { EditState } from '@/store/models.def';

declare const CrazyGames: any;

@Component({
  components: {},
})
export default class CrazyGamesSignInButton extends Vue {
  @Prop({ default: false }) public disabled!: boolean;

  public async getToken() {
    try {
      const token = (await CrazyGames.SDK.user.getUserToken()) as string;
      return token;
    } catch (error) {
      return this.promptCrazyGamesSignIn();
    }
  }

  public async promptCrazyGamesSignIn() {
    try {
      const user = await CrazyGames.SDK.user.showAuthPrompt();
      const token = (await CrazyGames.SDK.user.getUserToken()) as string;
      return token;
    } catch (error) {
      return '';
    }
  }

  public async signIn() {
    this.$emit('sending');
    try {
      const token = await this.getToken();
      if (token) {
        const result = await userx.signInWithCrazyGames(token);
        this.$emit('done');
      } else {
        this.$emit('active');
      }
    } catch (error) {
      this.$root.$emit('error', error);
      this.$emit('active');
    }
  }
}
