




























































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import userx from '@/store/modules/userx';
import { Route, RawLocation } from 'vue-router';
import router from '@/router';

import continueToUrl from '@/helpers/continueToUrl';
import { EditState } from '@/store/models.def';
import EmailInput from '@/components/inputs/EmailInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { PasswordStrength } from '@/components/inputs/password.def';
import StateButton from '@/components/inputs/StateButton.vue';
import { OAuthSigninMethod } from '@/store/firestore';
import CrazyGamesSignInButton from '@/components/black/CrazyGamesSignInButton.vue';
import globalx from '@/store/modules/globalx';

@Component({
  components: {
    EmailInput,
    PasswordInput,
    StateButton,
    CrazyGamesSignInButton,
  },
})
export default class Login extends Vue {
  public email = '';
  public password = '';
  public loginError = '';
  public validateNow = false;
  public loginState: EditState = 'active';

  public passwordStrength = PasswordStrength.Medium;
  public continueUrl = '/';

  public get saveEmailLabel() {
    const labels = {
      active: 'Sign In',
      sending: 'Signing In...',
      done: 'Success',
    };
    return labels[this.loginState];
  }

  // a = (b:any) => this.$store.dispatch('a',b)
  public async login() {
    this.validateNow = true;
    if (this.email !== null && this.password !== null) {
      this.loginState = 'sending';

      try {
        await userx.signIn({
          email: this.email,
          password: this.password,
        });
        this.loginState = 'done';
        setTimeout(() => continueToUrl(this.continueUrl), 1000);
      } catch (error) {
        this.loginState = 'active';
        this.loginError = (error as any).message || '';
      }
    }
  }

  public get pageReady() {
    if (userx.loginStatus === 'loading') {
      return 'auth';
    } else if (userx.loginStatus === 'logged out') {
      return 'done';
    } else if (userx.loginStatus === 'logged in') {
      return 'redirect';
    }
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    if (newVal === 'auth') {
      this.$root.$emit('loading', 'Authenticating...');
    } else {
      this.$root.$emit('loading', '');
    }
    if (newVal === 'redirect') {
      continueToUrl(this.continueUrl);
    }
  }

  public async signInAnonymously() {
    this.loginState = 'sending';
    try {
      await userx.signInAnonymously();
      this.loginState = 'done';
      setTimeout(() => continueToUrl(this.continueUrl), 1000);
    } catch (error) {
      this.loginState = 'active';
      this.loginError = (error as any).message || '';
    }
  }
  public async signInWithOAuth(method: OAuthSigninMethod) {
    this.loginState = 'sending';
    try {
      await userx.signInWithOAuth(method);
      this.loginState = 'done';
      setTimeout(() => continueToUrl(this.continueUrl), 1000);
    } catch (error) {
      this.loginState = 'active';
      this.loginError = (error as any).message || '';
    }
  }

  public beforeRouteEnter(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Login) => any) | void) => void,
  ): void {
    next((vm) => {
      vm.continueUrl = to.query.continueUrl
        ? (to.query.continueUrl as string)
        : from.fullPath;
      vm.email = to.query.email ? (to.query.email as string) : '';
    });
  }
  public beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Login) => any) | void) => void,
  ): void {
    next();
    if (to.query.continueUrl) {
      this.continueUrl = to.query.continueUrl as string;
    }
  }
  public get showCrazyGamesButtons() {
    return globalx.showCrazyGamesButtons;
  }
}
